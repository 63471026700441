import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner } from 'reactstrap';
import SpaceCard from '../spaces/SpaceCard';
import SpaceCarouselModal from '../spaces/SpaceCarouselModal';
import { isEmpty } from 'lodash';

class Spaces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSpaceId: null,
      randomizedSpaces: []
    };
  }

  componentDidMount() {
    const { getSpaces, spaces } = this.props;

    this.setState({ randomizedSpaces: spaces });
    getSpaces();
  }

  componentDidUpdate(prevProps) {
    const { spaces } = this.props;

    if(spaces.length !== prevProps.spaces.length)
      this.setState({ randomizedSpaces: spaces });
  }

  setSelectedSpace(selectedSpaceId) {
    this.setState({ selectedSpaceId });
  }

  shuffleArray(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  render() {
    const { selectedSpaceId, randomizedSpaces } = this.state;
    const { auth } = this.props;

    const spaceCards = randomizedSpaces.map((space) => {
      return <SpaceCard
        key={space.id}
        space={space}
        anonymous={!auth.token}
        setSelectedSpace={this.setSelectedSpace.bind(this)} />;
    });

    if(isEmpty(randomizedSpaces)){
      return ( <Spinner style={{width: '50px', height: '50px', position: 'fixed', left: '50%', top: '50%'}} /> )
    } else {
      return (
        <Container className="mt-4">
          <Row>
            <Col md="12">
              <h1 className="mt-5 mb-3">Find the perfect place for your gathering</h1>
            </Col>
          </Row>
          <Row>
            { spaceCards }
          </Row>
          <SpaceCarouselModal
            spaceId={selectedSpaceId}
            setSelectedSpace={this.setSelectedSpace.bind(this)} />
        </Container>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    spaces: state.spaces,
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSpaces: () => dispatch({ type: "GET_SPACES" })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Spaces);
