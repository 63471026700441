import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducer';
import { reducer as toastrReducer } from 'react-redux-toastr';
import spaceReducer from './spaceReducer';
import userReducer from './userReducer';
import eventReducer from './eventReducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  toastr: toastrReducer,
  user: userReducer,
  spaces: spaceReducer,
  events: eventReducer,
});
