import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import {
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarCheck } from '@fortawesome/free-solid-svg-icons'
 
import AccountNav from './AccountNav';

class MainNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleBrandClick(e) {
    e.preventDefault();
    this.props.navigateTo("/");
  }

  handleLoginClick(e) {
    e.preventDefault();
    this.props.navigateTo("/login");
  }

  handleSpacesClick(e) {
    e.preventDefault();
    this.props.navigateTo("/places");
  }

  render() {
    const { isOpen } = this.state;
    const { auth: { token } } = this.props;

    return (
      <Navbar color="info" dark expand="md" fixed="top">
        <NavbarBrand href="#" onClick={this.handleBrandClick.bind(this)}>
          <FontAwesomeIcon icon={faCalendarCheck} />&nbsp;&nbsp;Plafora
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle.bind(this)} />
        <Collapse isOpen={isOpen} navbar>
          <Nav navbar>
            <NavLink href="#!" onClick={this.handleSpacesClick.bind(this)}>Places</NavLink>
          </Nav>
          <Nav className="ml-auto" navbar>
            { token && <AccountNav /> }
            { !token && <NavItem>
              <NavLink href="#" onClick={this.handleLoginClick.bind(this)}>Login</NavLink>
            </NavItem> } 
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    navigateTo: (route) => {
      dispatch(push(route));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);
