import React, { Fragment } from 'react';
import './App.scss';
import { history } from './store';

import { Switch, Route } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import Navbar from './components/nav/Navbar';
import ReduxToastr from 'react-redux-toastr';
import ScrollToTop from './components/misc/ScrollToTop';

import Home from './components/screens/Home';
import Login from './components/screens/Login';
import Register from './components/screens/Register';
import Spaces from './components/screens/Spaces';
import Space from './components/screens/Space';
import Profile from './components/screens/Profile';
import RequestConfirmation from './components/screens/RequestConfirmation';
import NotFound from './components/screens/NotFound';
import ReactGA from 'react-ga';


ReactGA.initialize('UA-153830364-1');
ReactGA.pageview('/');


function App() {
  return (
    <Fragment>
      <div className="App">
        <ReduxToastr timeOut={4000} transitionIn="fadeIn" transitionOut="fadeOut" />
        <ConnectedRouter history={history}>
          <ScrollToTop />
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/places" component={Spaces} />
            <Route exact path="/place/:id" component={Space} />
            <Route exact path="/request/:id/confirmation" component={RequestConfirmation} />
            <Route component={NotFound} />
          </Switch>
        </ConnectedRouter>
      </div>
      <footer>
        <div className="text-muted mb-2">
          Contact for Comments and Feedback: <a href="mailto:jon@plafora.com">jon@plafora.com</a>
        </div>
        <div className="text-muted">© 2019 - Plafora is a brand name owned by Nadabot, LLC.</div>
      </footer>
    </Fragment>
  );
}

export default App;
