import { unauthenticatedPost } from './api_service';

const AuthService = {
  login: ({email, password}) => {
    return unauthenticatedPost('/rest-auth/login/', { email, password } );
  },

  register: ({ email, password1, password2 }) => {
    return unauthenticatedPost('/rest-auth/registration/', { email, password1, password2 });
  }
}

export default AuthService;
