import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers';
import { save, load, combineLoads } from 'redux-localstorage-simple';
import rootSaga from '../sagas';

const logger = createLogger();
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  createRootReducer(history),
  combineLoads(
    load({
      states: [
        'auth',
        'user',
        'spaces',
      ],
      namespace: 'nadabot'
    }),
  ),
  compose(
    applyMiddleware(
      routerMiddleware(history),
      logger,
      sagaMiddleware,
      save({
        states: [
          'auth',
          'user',
          'spaces',
        ],
        namespace: 'nadabot',
        debounce: 2000
      }),

    ),
  )
);

sagaMiddleware.run(rootSaga);

export default store;
